.MuiStepIcon-root.MuiStepIcon-active {
    color: $blue !important;
}
.MuiStepIcon-root.MuiStepIcon-completed{
    color: $secondary !important;
}

.pha-documents{
    .col1-old{
        width: 15%;
    }
    .col2-old{
        width: 60%;
    }
    .input-label{
        font-size: .875rem;
    }
    p{
        font-size: .75rem;
    }
    &.table{
        td{
            padding: 0.75rem 0.75rem 0rem 0.75rem;
        }
    }
    input{
        &:focus{
            outline: 0;
        }
    }
}

.regulations{
    p{
        margin-bottom: .625rem;
    }
    label{
        display: initial;
    }
}

.alert-small-text{
    color: $red;
    font-size: .75rem;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-weight: 500;
    position: relative;
}

.table-cgreen{
    &.table{
        thead{
            th{
                background-color: #d8fbf0;
            }
        }
        th, td{
            padding: .5rem 0.75rem;
        }
        td{
            vertical-align: middle;
            i{
                color: #50ad90;
            }
        }
    }
    .asterisk {
        color: #D8000C !important;
        font-size: 8px;
        position: relative;
        right: 3px;
        top: -8px;
    }
}

.pha-step2{
    .alert-small-text{
        position: absolute;
    }
}

.file-allowed{
    font-size: 13px;
    color: #5c9ad0;
    float: right;
    display: inline-block;
    font-weight: 500;
}

.MuiTooltip-tooltip{
    background-color: $black !important;
    font-size: 12px !important;
}

.btn-red{
    background: $primary;
    padding: .3125rem .625rem;
    border-color: $primary;
    margin-top: 0.150rem;
    margin-bottom: 0.150rem;
}
