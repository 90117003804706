// This file contains basic typography style.

html,
body {
  //color: $black;
  font-size: 16px; // 1rem = 16px;
  font-family: $montserrat;
  color: $black;
  @include mq("tablet-wide", max) {
    font-size: 15px;
  }
  @include mq("tablet", max) {
    font-size: 14px;
  }
  @include mq("tablet-small", max) {
    font-size: 13px;
  }
  @include mq("phone-wide", max) {
    font-size: 12px;
  }
}

.title {
  font-family: $league;
}

.roboto-font{
  font-family: $roboto;
}

.font-16{
  font-size: 1rem;
}

.woc-portal-link{
  font-size: .875rem;
  color: $primary;
  font-weight: 600;
}
