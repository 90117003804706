.login-wrapper{
    color: $white;
}

.login-left-bg{
    background: url("../../imgs/login-bg-left.jpg");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: table;
    position: relative;
   
    img{
        position: absolute;
        top: 2.5rem;
        @include mq("phone-wide", max) {
            width: 15.625rem;
        }
    }

    .login-left-content{
        display: table-cell;
        vertical-align: middle;
    }

    .login-title{
        font-size: 2.875rem; //46px;
        font-weight:bold;
        padding-top: 3.125rem;
        padding-bottom: 1.875rem;
        text-shadow: 2.939px 4.045px 1.84px rgba(0, 0, 0, 0.22);
    }

    p{
        font-size: 1rem;
    }
}

.login-right{
    background: $secondary;
    height: 100vh;
    display: table;

    .login-box{
        padding: 6.25rem;
        display: table-cell;
        vertical-align: middle;

        .form-control{
            border: 1px solid #8b8b8b;
            border-radius: .25rem;
            height: 3.125rem;
        }

        a{
            color: #060606;
            font-size: .875rem;
        }
    }
}

