.landing{
    align-items: center;
    background: $blue;
    padding: 1.25rem;

    @include mq("desktop-wide", min) {
        display: flex;
        padding: 5rem;
    }

    @include mq("phone", max) {
        padding: .625rem;
    }

    .main{
        @include mq("desktop-wide", max) {
            padding-bottom: 1rem;
        }
    }
}

.thankyou{
    @include mq("tablet-wide", min) {
        display: flex;
        padding: 5rem;
    }
}

.landing-fp{
    display: flex;
}

.landing-page #root{
    width: 100%;
}

.welcome-box{
    background-color: $white;
    border: 12px solid #e1d547;
}
.welcomeoc-program{
    .btn{
        padding: 0;
        text-transform: initial;
    }
    h2{
        color: $black;
        font-weight: bold;
        padding-top: 0.9375rem;
    }
    p{
        font-size: 0.75rem;
        color: #1f1f1f;
        font-style: italic;
        font-weight: 500;
        line-height: initial;
    }
    label{
        color: #1f1f1f;
        font-size: 0.75rem;
        font-weight: bold;
    }
}

.welcom-user-title{
    font-size: 16px;
    color: $secondary;
    margin: 0;
    text-align: right;
}

.landing-left{
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}