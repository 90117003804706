// This file contains bootstrap variable overrides.

//colors
$primary: #e13e3b !default;
$secondary: #70c4aa !default;
$light: #f7f7f7 !default;
$black: #020202 !default;
$blue: #5c9ad0 !default;

// quick
$enable-rounded: false !default;
