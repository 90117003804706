// This file contains CSS helper classes.

// Clear inner floats
.clearfix::after {
    clear: both;
    content: "";
    display: table;
}

// box shadow
.box-shadow {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14) !important;
}

.card-shadow {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.43);
}

.no-border-radius {
    border-radius: 0 !important;
}

// authentication message
.Toast__toast___XXr3v {
    color: $white !important;
    background: $danger !important;
    font-weight: 500;
}

.main {
    padding: 0 .9375rem; // 0 15px
    margin-top: 4rem;
}

.page-not-found-main {
    padding: 0 .9375rem; // 0 15px
    margin-top: 7rem;
}