.form-header{
    background: $primary;
    padding: 1.875rem 0;
    h1{
        font-size: 1.875rem; //30px
        color: $white;
        font-weight: bold;
    }
}

.user-logo{
    img{
        margin:0.625rem 0;
    }
    h2{
        font-size: 1.875rem; //30px
        color: $secondary;
        font-weight: bold;
    }
}

.form-box{
    border: 5px solid #e1d547;
    padding: 1.5625rem;
}

p{
    font-size: 0.875rem; //14px
}

.form-sub-category{
    padding-left: 1.5rem;
}

.custom-control{
    padding-bottom: 0.3125rem;
}

.wco-input{
    .width-67 {
        @include mq("desktop", max) {
            width: 60% !important;
        }
        @include mq("tablet", max) {
            width: 100% !important;
        }
    }
    .width-78 {
        @include mq("desktop", max) {
            width: 74% !important;
        }
        @include mq("tablet", max) {
            width: 100% !important;
        }
    }
    .width-47 {
        @include mq("desktop", max) {
            width: 45% !important;
        }
        @include mq("tablet", max) {
            width: 100% !important;
        }
    }
}

.form-subtitle{
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    padding: 2.1875rem 0;
}

.form-subtitle2{
    font-size: 1.125rem;
    font-weight: bold;
    padding: 0.625rem 0;
}

.form-footer{
    background: $primary;
    text-align: center;
    font-size: 0.75rem;
    color: #fff;
    padding: 0.3125rem 0;
    p{
        margin: 0;
    }
}

.info-msg,
.success-msg,
.warning-msg,
.error-msg {
  margin: 5px 0;
  padding: 5px;
  border-radius: 3px 3px 3px 3px;
  font-size: .875rem;
  i{
      margin-right: .625rem;
  }
}

.info-msg {
    color: #059;
    background-color: #BEF;
  }
.success-msg {
    color: #270;
    background-color: #DFF2BF;
}
.warning-msg {
    color: #9F6000;
    background-color: #FEEFB3;
}
.error-msg {
    color: #D8000C;
    background-color: #FFBABA;
}

.success-icon{
    font-size: 80px;
    color: $secondary;
}