// This file contains all styles related to the footer.

footer{
    background:$black;
    font-size:14px;
    color: $white;
    padding: .3125rem 0;
    text-align: center;
    font-family: $roboto;
}

body, .sticky-footer-wrapper {
    min-height:100vh;
 }