.uw-autocomplete{
    .form-control{
        border: 1px solid #ced4da;
        .MuiInput-underline:before{
            display: none;
        }
        .MuiInput-underline:after{
            display: none;
        }
        input{
            padding: 0.375rem 0.75rem !important;
            font-size: .8125rem;
        }
    }
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"]{
        padding-bottom: 0 !important;
    }
}

.grid-text-field{
    font-family: "Montserrat",sans-serif !important;
    .form-group {
        @include mq("tablet-wide", max) {
            margin-bottom: 6px;
        }
    }
    .MuiFormLabel-root.Mui-focused{
        color: #020202;
        font-weight: 500;
    }
    .MuiInputLabel-formControl {
        font-family: "Montserrat",sans-serif !important;
        color: #020202;
        font-weight: 400;
        top: 0px;
        left: 10px;
        z-index: 1;
        @include mq("tablet", max) {
            top:-2px;
        }
    }
    label + .MuiInput-formControl {
        margin-top: 3px;
        border: 1px solid #ced4da;
        background: #fff;
        font-family: "Montserrat",sans-serif !important;
    }
    input{
        padding: 0.375rem 0.75rem !important;
        font-size: .8125rem;
    }
    .MuiFormControl-root{
        width: 100%;
    }
    .MuiInput-underline:before{
        display: none;
    }
    .MuiInput-underline:after{
        display: none;
    }
    .MuiFormHelperText-root.Mui-error {
        position: absolute;
        bottom: -21px;
    }
}

.MuiAutocomplete-option{
    font-size: .8125rem;
}

.uw-grid{
    table{
        i{
            color: #dc3545;
        }
        th, td{
            font-size: 13px;
        }
    }    
}

.bluebg-section{
    background: #bcd5ea;
    padding: 10px;
}

.autocomplete-disable{
    .MuiInputBase-formControl{
        height: calc(1.5em + 0.75rem + 2px);
    }
    input{
        font-size: 14px !important;
    }
}