// This file contains all styles related to the header.
.navbar{
    background: #eeefef;
}
.navbar-brand {
    cursor: pointer !important;
}
header{
    .navbar-inner {
        padding: .9375rem 1.875rem; //15px 30px

        @include mq("desktop", max) {
            padding: 1.25rem 1.875rem; //20px 30px
        }

        @include mq("tablet-mini", max) {
            .logo {
                height: 23px;
            }
        }

        @include mq("phone-wide", max) {
            padding: 1rem;

            .logo {
                height: 19px;
            }
        }
    }
}

.uw-dropdown{
    .dropdown-menu{
        padding: 0px 1.25rem;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.43);
        border: 0;
        .dropdown-item{
            color: $primary;
            font-size: 0.875rem;
            border-bottom: 1px solid #c6e0f2;
            padding: 0.5rem 0;
            &:hover, &:active{
                background-color: transparent;
            }
            &:focus{
                outline: none;
            }
            &:last-child{
                border-bottom: 0;
            }
            span{
                width: 25px;
                display: inline-block;
            }
        }
    }
}